import React from 'react'
import { useEffect,useState } from 'react';
import Abouts from './Abouts';
import Ads from './Ads';
import Ads_one from './Ads_one';

function Games() {
 const[data_games,setData_games]=useState(null);
const getGames=async(e)=>{
  
  e.preventDefault();
await fetch("https://free-epic-games.p.rapidapi.com/free",
 {
  "method": "GET",
   "headers": { "x-rapidapi-host": "free-epic-games.p.rapidapi.com",
    "x-rapidapi-key":"2c2d2c930bmsh34aba5ca3857f41p13773fjsn95f9d1e0ed98"
     } })
      .then(response =>
       { 
       console.log(response);
       return response.json()
        })
        .then(data=>{
        setData_games(data);
        console.log(JSON.stringify(data))
        })
 .catch(err => { console.error(err); });
};


  return (
    <div>
        
<section class="section_id">
<div class="flex_id">
<h1>Search for random Epic Game by clicking the search button.</h1>
<button class="btn" onClick={(e)=>getGames(e)}>Search</button>
</div><Ads_one/>
</section>
<section class="sectionid">
  {data_games && data_games.freeGames.current.map(x=><div>

    <div class="ron_id">
<h1>Title: {x.title}</h1>
<h2>Description: {x.description}</h2>
<span>{x.effectiveDate?x.effectiveDate.substring(0,10):x.effectiveDate}</span>
<h2>OFFER TYPE:<span>{x.offerType}</span></h2>
<h2>STATUS:<span>{x.status}</span></h2><br />
<div>
  {x.keyImages.map(el=><div>
    <img src={el.url} alt={el.type} width="100px" height='100px'/>
  </div>)}
</div>
<p>Cluster:Cluster here</p>
<hr />
</div>
  </div>)}
</section>
<section class="section_id">
<div class="rem_id">
<img src="./img/team/01.jpg" alt="abeg image" />
</div>
</section>
<Ads/>
<Abouts type={"free_games"}/>
    </div>
  )
}

export default Games