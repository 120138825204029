import React, { useEffect } from 'react'
import { useState } from 'react'
import Abouts from './Abouts';
import Ads from './Ads';

function Text_extractor() {
    const[img,setImg]=useState(null);
    const[data_,setData_]=useState(null);
   const[images,setImages]=useState(null);
const handleImg=(e)=>{
setImg(e.target.files[0]);
setImages(URL.createObjectURL(e.target.files[0]));
};
const getData=(e)=>{
    e.preventDefault();
    //console.log(img.name)
    const form = new FormData(); 
    form.append("srcImg", img.name);
     form.append("Session", "string"); 
     fetch("https://pen-to-print-handwriting-ocr.p.rapidapi.com/recognize/",
      { 	"method": "POST", 
      	"headers": { 
    "content-type": "multipart/form-data; boundary=---011000010111000001101001", 
		"x-rapidapi-host": "pen-to-print-handwriting-ocr.p.rapidapi.com", 		"x-rapidapi-key":"2c2d2c930bmsh34aba5ca3857f41p13773fjsn95f9d1e0ed98"
    	}})
        .then(response =>
            { 
            console.log(response);
            return response.json()
             })
             .then(data=>{
            setData_(data)
             console.log(JSON.stringify(data))
             }) 
    .catch(err => { 	console.error(err); });
return
}
    return (
    <div>
  <section class="section_id">
<div class="flex_id">
    <form onSubmit={(e)=>getData(e)}>
    <h2>Choose an image</h2>   
<input type='file' className='btn' onChange={(e)=>handleImg(e)} required/>
{
img !== null?<button className='btn' type='submit'>Extract text</button>:null
}
</form>
</div>
<div class="flex_id">
    {
        img !== null?<div>
        <img src={images} alt="abeg give me img" />
        <h3>Extracted text below</h3></div>
:'text will be shown below'
    }
</div>
</section>
<Ads/>   
<section class="section_id">
<div class="ron_id">
<h1>TEXT EXTRACTED:</h1>
<p>{data_ && data_.value?data_.value:'Choose an image'}</p>
<hr />
</div>
</section>
<Abouts type={"text_extractor"}/>
    </div>
  )
}

export default Text_extractor