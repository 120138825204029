import React from 'react'
import { useState } from 'react'
import Abouts from './Abouts';

function Reverse() {
    const[name,setName]=useState('');
    const[selected,setSelected]=useState(" ");
    const text_to_reverse=()=>{
        if(name !== ''){
        return    name.split(" ").reverse().join(selected)
        }else{
            return '...'
        }
    }
  return (
    <div> 
        <section class="section_id">
<div class="flex_id">
    <h4>Reverse text with:</h4>
<select onChange={(e)=>{
        const select = e.target.value;
        setSelected(select)
    }}>
        <option value={' '}>space</option>
        <option value={'-'}>dash</option>
        <option value={'%'}>percent</option>
        <option value={'\n'}>break line</option>
        <option value={'@'}>@</option>
        <option value={'_'}>underscore</option>
    </select> 
    <br/>
<small>or</small>
<div>
    <input value={selected} onChange={(e)=>setSelected(e.target.value)} placeholder='e.g _ _' style={{border:'1px solid black',borderStyle:'dotted',width:"85%"}}/>
</div>
</div>
</section>
   
<section class="section_id"> 
<div class="textarea">
<textarea rows="13" placeholder="Text to reverse..." value={name} onChange={(e)=>setName(e.target.value)} >
</textarea>
<button class="btn">Reverse text</button>
</div>
<div class="textarea">
<h1>Reversed text</h1>
<textarea rows={'13'} value={text_to_reverse()}></textarea>
</div>
</section>
<Abouts type={'reverse'} />
    </div>
  )
}

export default Reverse