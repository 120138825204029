import React, { useState } from 'react'
import Abouts from './Abouts';
import Ads from './Ads';
import Ads_one from './Ads_one';

function Stocks() {
const[data_stocks,setData_stocks]=useState(null);
const[search,setSearch]=useState("");
const getStocks=async(e)=>{
  e.preventDefault();
await fetch(`https://bb-finance.p.rapidapi.com/market/auto-complete?query=+${search}`,
 { 
 "method": "GET",
  "headers": { "x-rapidapi-host": "bb-finance.p.rapidapi.com",
   "x-rapidapi-key":"2c2d2c930bmsh34aba5ca3857f41p13773fjsn95f9d1e0ed98"} })
.then(response =>
       { 
       console.log(response);
       return response.json()
        })
        .then(data=>{
       setData_stocks(data)
        console.log(JSON.stringify(data))
        })
   .catch(err => { console.error(err); });

}
  return (
    <div>
        
<section class="section_id">
<div class="flex_id">
<h1>Search for any Stock using the search box.</h1>
<input type="text" name="text" id="text" value={search} onChange={(e)=>setSearch(e.target.value)} placeholder="e.g apple" required />
<button class="btn" onClick={(e)=>getStocks(e)}>Search</button>
</div><Ads_one/>
</section>

<section >
{data_stocks && data_stocks.quote.map(x=>
<div>

<div class="ron_id">
<h1>Stocks name: {search}</h1>
<h2>Symbol:<span>{x.symbol}</span></h2>
<h2>Exchange:<span>{x.exchange}</span></h2><br />
<p>Country:{x.country}</p>
<p>FundamentalDataCurrency:{x.fundamentalDataCurrency}</p>
<p>Ticker:{x.ticker}</p>
<p>Security Type:{x.securityType}</p>

<hr />
</div>
</div>)}
</section>
<section class="section_id">
<div class="rem_id">
<img src='img/tools.png'/></div>
</section>
<Ads/>
<Abouts type={"stocks"}/>
    </div>
  )
}

export default Stocks