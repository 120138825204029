import { useState } from "react";
import {Link} from "react-router-dom";

export const Features = (props) => {
  const[search,setSearch]=useState("");
  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>234 TOOLS</h2>
        </div>
        <div class='flex_ide'>
          <input placeholder="Search for tools..." value={search} onChange={(e)=>setSearch(e.target.value)} name='search'/>
          
          <select onChange={(e)=>{
            const selected = e.target.value;
            setSearch(selected)
          }}>
            <option value=''>filter</option>
            <option value='suggest'>suggest</option>
            <option value='game'>game</option>
            <option value='jokes'>jokes</option>
            <option value='backlink'>backlink</option>
            <option value='sport'>sport</option>
            <option value='finance'>finance</option>
            <option value='web-tool'>Web tool</option>
          </select>
        </div>
        <div className='row'>
          {props.data
            ? props.data.filter(n=>n.cursor==true).filter((lists)=>{
              if(search==""){return lists}
              else if(lists.title.toLowerCase().includes(search.toLowerCase())
              ){return lists}
              else if(lists.type.toLowerCase().includes(search.toLowerCase())
              ){return lists}
            }).map((d, i) => (
                <div key={`${d.title}-${i}`} >
                 
        <div className='col-xs-6 col-md-3'>
        {' '}
                  <Link to={{pathname:`${d.route}`}}>
                  <i className={d.icon}></i>
                  </Link>
                  <Link to={{pathname:`${d.route}`}}>
                  <h3>{d.title}</h3>
                  </Link>
                  <p>{d.text}</p>
        </div>   
                </div>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  )
}
