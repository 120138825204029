import React,{useState} from 'react'
import Abouts from './Abouts';
import Ads from './Ads';
import Ads_one from './Ads_one';

function Coins() {
  const[data_,setData_]=useState(null);
  const[coin,setCoin]=useState("BTC/ETH");
const getCoins=async(e)=>{
  e.preventDefault();
await fetch(`https://coinranking1.p.rapidapi.com/coins?referenceCurrencyUuid=yhjMzLPhuIDl&timePeriod=24h&symbols=+${coin}&tiers=1&orderBy=marketCap&orderDirection=desc&limit=50&offset=0`, {
 "method": "GET", "headers": {
  "x-rapidapi-host": "coinranking1.p.rapidapi.com", "x-rapidapi-key":"2c2d2c930bmsh34aba5ca3857f41p13773fjsn95f9d1e0ed98"} })
 .then(response =>
 { 
 console.log(response);
 return response.json()
 })
 .then(data=>{
 setData_(data)
 console.log(JSON.stringify(data))
 })
    .catch(err => { console.error(err); });
}
  return (
    <div>
      

      <section class="section_id">
<div class="flex_id">
<h1>Select your coin exchange</h1>
<select onChange={(e)=>{
  const selected =e.target.value;
  setCoin(selected);
}}>
  <option value='BTC/ETH'>BTC/ETH</option>
  <option value='BTC/DAI'>BTC/DAI</option>
  <option value='BTC/USDT'>BTC/USDT</option>
  <option value='USDT/ETH'>USDT/ETH</option>
</select>
<button class="btn" onClick={(e)=>getCoins(e)}>Search</button>
</div><Ads_one/>
</section>
      
<section class="section_id">
<div class="ron_id">
<h1>COIN EXACHANGE: {coin}</h1>
<h2>Total coin:<span>{data_ && data_.data.stats.totalCoins}</span></h2>
<h2>Total Market:<span>{data_ && data_.data.stats.totalMarkets}</span></h2><br />
<p>Total Exchange: {data_ && data_.data.stats.totalExchanges}</p>
<p>Total MarketCap: {data_ && data_.data.stats.totalMarketCap}</p>
<hr />
</div>
</section>
<Ads/>
<Abouts type={"crypto"}/>
    </div>
  )
}

export default Coins