import React, { useState,useRef } from 'react'
import { useEffect } from 'react';
import Abouts from './Abouts';
import Ads from './Ads';

function Text_to_speech() {
const[txt,setTxt]=useState('');
const tts = window.speechSynthesis;
const [voices,setVoices]= useState(null);
const[show,setShow]=useState(false);
const voiceList = useRef(null);
const onClickEvent=()=>{
//e.preventDefault();
setVoices(tts.getVoices());
voiceList.current.innerHTML = '';
voices && voices.forEach((voice)=>{
    const listItems =document.createElement('option');
    listItems.textContent =voice.name;
    listItems.setAttribute('data_lang',voice.lang);
    listItems.setAttribute('data_name',voice.name);
    voiceList.current.appendChild(listItems);
});
voiceList.current.selectedIndex = 0;
console.log(voiceList);
setShow(true)
};

    if(speechSynthesis !== undefined){
        speechSynthesis.onvoiceschanged = onClickEvent;
    }

useEffect(()=>{
   onClickEvent()
},[]);
const speak=()=>{
    var tospeak = new SpeechSynthesisUtterance(txt);
   //console.log(tts)
    var selected_voice = voiceList.current.selectedOptions[0].getAttribute('data-name');
    console.log(selected_voice)
   voices && voices.forEach((voice)=>{
     if(voice.name == selected_voice){
         tospeak.voice =voice 
     }
    });
   // console.log(selected)
    tts.speak(tospeak);
}
  return (
    <div>

<section class="section_id">
<div class="textarea">
<select ref={voiceList}></select>
<textarea rows="12" onChange={(e)=>setTxt(e.target.value)} placeholder="Word to speak...">
</textarea>
<button class="btn" onClick={()=>onClickEvent()}>Get Voice</button>
{
    show? <button class="btn" onClick={()=>speak()}>Convert</button>
    :null
}
</div>
<div class="textarea">
<h1>Audio:</h1>
{
tts.speaking == false?<p>Ensure you click GetVoice twice</p>:<p>Speaking</p>
}
<audio>
</audio>
</div>
</section>
<Ads/>
<Abouts type={"txt_to_speech"}/>
    </div>
  )
}

export default Text_to_speech