import React, { useState,useContext } from 'react'
import axios from 'axios'
import { create } from './App';

function Emails() {
    const[email,setEmail]=useState('');
    const {setShowemail,msg} =useContext(create)
    const getDate=()=>{
        const date = new Date().toISOString();
        return date
      }
    const unSubmit= async(e)=>{
e.preventDefault();
const msgs={
    name:"No name",
    email:email,
    subject:"Suscribed to 234tools",
    message:'Good luck with your tools',
    date:getDate()
};
 await axios.post("https://heischimdi.herokuapp.com/protfolio/",msgs).then((response)=>{
localStorage.setItem('234tools-email',email);
setShowemail("false");
alert(`You've succesfully suscribed to 234tools`);
window.location.reload()
});
    }
const handleC=()=>{
    setShowemail(false)
}
  return (
    <div className='containerr'>
<div class="popup">
<img src="img/imgthree.jpg" />
<span onClick={(e)=>handleC(e)} style={{color:"red",fontSize:"39px",float:"right",margin:"13px",cursor:"pointer"}}>&times;</span>
<h2>{msg.text}</h2>
<form onSubmit={(e)=>unSubmit(e)}>
<input type="email" value={email} onChange={(e)=>setEmail(e.target.value)} name="Email" placeholder="Email"  required />
<button class="btn" type="submit" >Suscribe</button>
</form>
<a></a>
<small><a href="https://ax-short.netlify.app?n=507780&p=4893" >About Devloper ?</a></small>
</div>
    </div>
  )
}

export default Emails