import React, { useState } from 'react'
import Abouts from './Abouts';
import Ads from './Ads';
import Ads_one from './Ads_one';

function Keyword() {
  
const[data_,setData_]=useState(null);
const[search,setSearch]=useState("Happy");
const getKeyword=async(e)=>{
  e.preventDefault();
  alert('web api no longer available')
window.location = '/';
  /*
await fetch(`https://keywords4.p.rapidapi.com/google-topLevel-10-keywords?search=+${search}&country=us`, {
 "method": "GET", "headers": {
  "content-type": "application/json", "x-rapidapi-host": "keywords4.p.rapidapi.com",
   "x-rapidapi-key":"2c2d2c930bmsh34aba5ca3857f41p13773fjsn95f9d1e0ed98" } })
   .then(response =>
   { 
   console.log(response);
   return response.json()
   })
   .then(data=>{
   setData_(data);
   console.log(JSON.stringify(data))
   })
     .catch(err => { console.error(err); });
*/
}
  return (
    <div>
  
<section class="section_id">
<div class="flex_id">
<h1>Insert any keyword to find Suggestions</h1>
<input type="text" name="text" id="text" value={search} onChange={(e)=>setSearch(e.target.value)} placeholder="e.g wishing for birthdays" required />
<button class="btn" onClick={(e)=>getKeyword(e)}>Find</button>
</div><Ads_one/>
</section>    


<section class="sectionid">
  {data_ && data_.googleGuggestedKeywords.map(x=><div>

    <div class="sec_id">
<h1> Text Input:</h1>
<p>{search}</p>
<h1>Suggest:</h1>
<p>{x}</p>
</div>
  </div>)}
</section>
<Ads/>
<Abouts type={"google_sugest"}/>
    </div>
  )
}

export default Keyword