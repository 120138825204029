import axios from 'axios';
import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { create } from '../App';
import Abouts from './Abouts';
import Ads_one from './Ads_one';
import SmoothScroll from 'smooth-scroll'
import Ads from './Ads';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})
function Shortlink() {
    //longURL,shortURL,date,myCode,urlID
    const[link,setLink]=useState('');
    const[mycode,setMycode]=useState('');
    const[newlink,setNewlink]=useState('Your new link wil show here');
    const[msgs,setMsgs]=useState('');
    const[urlId,setUrlId]=useState('')
    const generateCode=()=>{
     return Math.floor(Math.random() * 9)
    }
    const generateRandomCode=()=>{
        return Math.floor(Math.random() * 12)
   
    }
    const codes =()=>{
        let result = '';
        for(let i= 0;i< 6;i++){
             result += generateCode()
        }
     setMycode(result)  
    }
    const codesId =()=>{
        let result = '';
        for(let i= 0;i< 4;i++){
             result += generateRandomCode()
        }
     setUrlId(result) 
    }
useEffect(()=>{
    codesId()
    if(localStorage.getItem('234toolscode')){
        setMycode(localStorage.getItem('234toolscode'))
    }
    else{
       codes()
    }
},[])
const[show,setShow]=useState(false)
const {setShowemail,setMsg} =useContext(create)
const postLink= async(e)=>{
e.preventDefault();
if(localStorage.getItem('234tools-email')){

    setNewlink(`ax-short.netlify.app?n=${mycode}&p=${urlId}`);
    //localStorage.setItem('234toolscode',mycode)
    //alert(`Your link is:\nme.axgura.com?n=${mycode}&p=${urlId}`)
    //https://ax-short.netlify.app/
    //me.axgura.com
    //file:///C:/ax_short/frontend/index.html
    const data ={
        longURL:link,
        shortURL:`ax-short.netlify.app?n=${mycode}&p=${urlId}`,
        date:new Date().toISOString().substring(0,10),
        myCode:mycode,
        urlId:urlId,
        click:'1'
    }
    console.log(data);
    if(link !== ''){
        setMsgs('loading...')
        await  axios.post('https://ax-short.herokuapp.com/link234tools',data).then((response)=>{
            setMsgs('Sucessful \n Insure you relaod page before creating another link');
            localStorage.setItem('234toolscode',mycode);
    alert(`Your link is:\nme.axgura.com?n=${mycode}&p=${urlId}`);
            setShow(true);
            codesId()
        }).catch((err)=>{
            if(err){
                setMsgs('Something went wrong')
            }
        })
    }
    else{
        setMsgs('Input url')
    }
}else{
setShowemail(true);
setMsg({
    type:"red",
    text:"Suscribe to 234tools to use this tool for free",
    bool:true
})
}
};
const[data,setData]=useState(null);
const getData=async(e)=>{
    e.preventDefault()
    await axios.get('https://ax-short.herokuapp.com/link234tools-get')
    .then((response)=>{
        setData(response.data);
        console.log(data&&data)
    })/**
    .catch((err)=>{
        if(err){
            setMsg(err)
        }
    }) */
};
const handleDelete=async(id)=>{
await axios.get(`https://ax-short.herokuapp.com/link234tools-delete/${id}`).then((response)=>{
    alert(response.data);
}).catch((err)=>{
    alert("Please try again")
})
}

  return (
    <div>
        
<section class="section_id">
<div class="flex_id">
<h1>Enter the URL you want to shorten</h1>
<input type="text" name="text"  value={link} placeholder="URL here..." onChange={(e)=>setLink(e.target.value)} required />
<button class="btn" onClick={(e)=>postLink(e)}>Shorten</button><br/>
{msgs}
</div>
<div class="flex_id">
    <h1>LINK:</h1><br/>
    <p>{show?newlink:'link will show up here'}</p>
</div>
</section>
<div>
    <Ads_one />
</div>
<Ads/>
<div>
    <h1  style={{textAlign:'center'}}>Recent links and clicks </h1>
    <p style={{textAlign:'center'}}>Post your url code here to see details</p>
    <div  style={{textAlign:'center',justifyContent:'center',alignItems:'center'}} >
    <input value={mycode}  onChange={(e)=>setMycode(e.target.value)} placeholder='If have a link it will show here'/>
    <button onClick={(e)=>getData(e)}>GET</button>
 
{
    data && data.filter(n=> n.myCode == mycode ).map(x=><div key={x.id}>

<div class="flex_id">
<h5>Orignal Link: {x.longURL}</h5><br/>
<h6>Short Link:{x.shortURL}</h6>
<p>Clicks: <small>{x.click}</small></p>
<small>Date: {x.date}</small><br/>
<button style={{background:"red"}}  onClick={()=>handleDelete(x.id)} className='btn'>Delete</button>
</div>
    </div>)
}
</div>
</div>
<Abouts type={'shortlinker'} />
    </div>
  )

  }
export default Shortlink;