import React, { useState } from 'react'
import Abouts from './Abouts';
import Ads from './Ads';
import Ads_one from './Ads_one';

function Backlinks() {
  const [data_,setData_]=useState(null);
  const [search,setSearch]=useState("")
const getBacklink=async(e)=>{
  e.preventDefault();
 await fetch(`https://domain-da-pa-check.p.rapidapi.com/?domain=+${search}`, { 
 "method": "GET", "headers": { "x-rapidapi-host": "domain-da-pa-check.p.rapidapi.com", "x-rapidapi-key":"2c2d2c930bmsh34aba5ca3857f41p13773fjsn95f9d1e0ed98"} }) 
.then(response =>
       { 
       console.log(response);
       return response.json()
        })
        .then(data=>{
        setData_(data);
        console.log(JSON.stringify(data))
        })
 .catch(err => { console.error(err); });

}
  return (
    <div>
      
<section class="section_id">
<div class="flex_id">
<h1>Enter domain to check backlinks.</h1>
<input type="text" name="text" id="text" value={search} placeholder="Some text here ..." onChange={(e)=>setSearch(e.target.value)} required />
<button class="btn" onClick={(e)=>getBacklink(e)}>Search</button>
</div><Ads_one/>
</section>
<section class="section_id">
<div class="sec_id">
<h1> Backlink search:</h1>
<p>{search}</p>
<h1>Result:type:{data_ && data_.result}</h1>
<h3>Domain: {data_ && data_.body.domain}</h3>
<h4>Country: {data_ && data_.body.alexa_country}</h4>
<p>Da_score: {data_ && data_.body.da_score}</p>
<small>alexa_rank: {data_ && data_.body.alexa_rank}</small>
</div>
</section>
    <Ads/> 
    <Abouts type={"shortener"}/>
    </div>
  )
}

export default Backlinks