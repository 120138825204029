import React, { useState } from 'react'
import Abouts from './Abouts';
import Ads from './Ads';
import Ads_one from './Ads_one';

function Gig() {
const[data_,setData_]=useState(null);
const[search,setSearch]=useState("");
const getGigs=async(e)=>{
e.preventDefault();
alert('web api no longer available')
window.location = '/';
/*
await fetch(`https://fiverr-gigs.p.rapidapi.com/fiverr_gigs?search_query=+${search}`, {
 "method": "GET", "headers": { 
 "x-rapidapi-host": "fiverr-gigs.p.rapidapi.com", 
 "x-rapidapi-key":"2c2d2c930bmsh34aba5ca3857f41p13773fjsn95f9d1e0ed98"
 } })
.then(response =>
       { 
       console.log(response);
       return response.json()
        })
        .then(data=>{
        setData_(data);
        console.log(JSON.stringify(data))
        })
   .catch(err => { console.error(err); });
*/

}
  return (
    <div>
    
<section class="section_id">
<div class="flex_id">
<h1>Suggest for any Gig here</h1>
<input type="text" name="text" id="text" value={search} onChange={(e)=>setSearch(e.target.value)} placeholder="e.g clothier" required />
<button class="btn" onClick={(e)=>getGigs(e)}>Search</button>
</div><Ads_one/>
</section>



<section class="section_id">
<div class="sec_id">
<h1> Search Input:</h1>
<p>{search}</p>
<h1>Suggest Gigs:</h1>
<div>
  {
   data_ && data_.response.map(x=><div>
      <div className='flex_id'>
        <h3>Title: {x.gig_title}</h3>
        <p>name: {x.seller_name}</p>
        <small>Online: {x.is_seller_online}</small>
        <img src={x.seller_image} style={{width:"100px",height:"100px",textAlign:"center"}}/>
        </div>
    </div>)
  }
</div>
</div>
</section>
<Ads/>
<Abouts type={"fiverr"}/>
    </div>
  )
}

export default Gig