import React,{ useState, useEffect,createContext} from 'react'
import Create from './Create'
import Football from './mytools/Football';
import Games from './mytools/Games';
import Keyword from './mytools/Keyword';
import Stocks from './mytools/Stocks';
//import Symbols from './mytools/Symbols';
import Gig from './mytools/Gig'
import Coins from './mytools/Coins';
import Dadjokes from './mytools/Dadjokes';
import BacklinkG from './mytools/BacklinkG';
import BacklinkR from './mytools/BacklinkR';
import Backlinks from './mytools/Backlinks';
import { Footer } from './components/footer'
import { Contact } from './components/contact'
import { Navigation } from './components/navigation'
import JsonData from './data/data.json'
import {BrowserRouter as Router,Route,Switch} from "react-router-dom";
import Privacy from './Privacy';
import Text_to_speech from './mytools/Text_to_speech';
import Speech_to_text from './mytools/Speech_to_text';
import Text_extractor from './mytools/Text_extractor';
import Reverse from './mytools/Reverse';
import Shortlink from './mytools/Shortlink';
import Img_resize from './mytools/Img_resize';
import Emails from './Emails';

export const create = createContext({});
const App = () => {
 const[msg,setMsg]=useState({
   type:"red",
   text:"Suscribe to 234tools to get update about new tools everymonth",
   bool:true
 });
 const[showemail,setShowemail]=useState(false);
 const shoes=()=>{

  setTimeout(()=>{
    if(localStorage.getItem('234tools-email')){
      setShowemail(false);
    }else{
      setShowemail(true);
    }
    },5000)
  
 };
 useEffect(()=>{
shoes()
 },[])

  return (
    <div>
              <create.Provider value={{msg,setMsg,showemail,setShowemail}}>

      {
        showemail?
        <div>
          <Emails />
        </div>
        :
        <div>
          
       <Navigation />
       <br/>
       <br/>
       <br/>
       <br/>
      <Router>
        <Switch>
          <Route exact path='/' component={Create} />
          <Route path='/backlinkg' component={BacklinkG} />
          <Route path='/backlinkr' component={BacklinkR} />
          <Route  path='/backlinks' component={Backlinks} />
          <Route path='/coins' component={Coins} />
          <Route path='/dadjokes' component={Dadjokes} />
          <Route path='/football' component={Football} />
          <Route  path='/games' component={Games} />
          <Route path='/gigs' component={Gig} />
          <Route path='/keyword' component={Keyword} />
          <Route path='/stocks' component={Stocks} />
          <Route path="/txt_to_speech" component={Text_to_speech}/>
          <Route path="/speech_to_text" component={Speech_to_text} />
          <Route path="/text_extractor" component={Text_extractor} />
          <Route path="/reverse_txt" component={Reverse} />
          <Route path="/shortlink" component={Shortlink} />
          <Route path="/img_resize" component={Img_resize}/>
        </Switch>
      </Router>
      <Contact data={JsonData.Contact}/>
      <Privacy/>
      <Footer />
        </div>

      }
            </create.Provider>   

    </div>
  )
}

export default App
