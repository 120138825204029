import React,{ useState, useEffect } from 'react'
import { Header } from './components/header'
import { Features } from './components/features'
import { About } from './components/about'
import { Services } from './components/services'
import { Gallery } from './components/gallery'
import { Testimonials } from './components/testimonials'
import JsonData from './data/data.json'
import SmoothScroll from 'smooth-scroll'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})


function Create() {
     const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])
  return (
    <div>
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      
      <About data={landingPageData.About} />
      {/*
        <Services data={landingPageData.Services} />
        <Testimonials data={landingPageData.Testimonials} />
         <Gallery />
        */}
     
     
    </div>
  )
}

export default Create