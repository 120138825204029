import React, { useState,useRef } from 'react'
import Abouts from './Abouts';
import Ads from './Ads';

function Speech_to_text() {
  const converted_txt = useRef(null);
  const[speech,setSpeech]=useState(false);
 console.log(window.SpeechRecognitionResult)
 const convertToTxt=()=>{
    setSpeech(true);
    alert("This tool is Unavailable");
    window.location = '/';
   /* if(window.SpeechRecognition){
    window.SpeechRecognition = window.webKitSpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.interminResults = true;
    recognition.addEventListener('result',e=>{
     const transcript_ = Array.from(e.results)
   .map(result=>result[0])
   .map(result=>result.transcript
    });
    converted_txt.current.innerHTML = transcript_;
    if(speech == true){
      recognition.start()
    }  }*/
  }
  return (
    <div>
        
<section class="section_id">
<div class="textarea">
<textarea rows="12" placeholder="Word to speak...">
</textarea>
<button class="btn" onClick={()=>convertToTxt()}>Start</button>
</div>
<div class="textarea">
<h1>Converted Speech:</h1>
<textarea ref={converted_txt} placeholder='...' rows={12}>

</textarea>
</div>
</section>
<Ads/>
<Abouts type={"speech_to_txt"}/>
    </div>
  )
}

export default Speech_to_text