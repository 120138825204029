import React from 'react'
import { useState } from 'react';
import Abouts from './Abouts';
import Ads from './Ads';
import Ads_one from './Ads_one';

function BacklinkR() {
  const[data_,setData_]=useState(null);
  const[search,setSearch]=useState("");
const getBacklinkR=async(e)=>{
  e.preventDefault();
await fetch(`https://backlink.p.rapidapi.com/getbacklinks/v1/?domain=+${search}&offset=0&filter_isdofollow=false&filter_linkfrom=example.com%2C%20domain.com`, {
 "method": "GET", "headers": {
  "x-rapidapi-host": "backlink.p.rapidapi.com", "x-rapidapi-key":"2c2d2c930bmsh34aba5ca3857f41p13773fjsn95f9d1e0ed98"}
   })
  .then(response =>
  { 
  console.log(response);
  return response.json()
  })
  .then(data=>{
  setData_(data);
  console.log(JSON.stringify(data))
  })
    .catch(err => { console.error(err); });


}
  return (
    <div>
      
<section class="section_id">
<div class="flex_id">
<h1>Enter domain in your search box.</h1>
<input type="text" name="text" id="text" value={search} placeholder="Domain here ..." onChange={(e)=>setSearch(e.target.value)} required />
<button class="btn" onClick={(e)=>getBacklinkR(e)}>Search</button>
</div><Ads_one/>
</section>
<section class="section_id">
<div class="sec_id">
<h1>Domain:</h1>
<p>{search}</p>
<h1>FOUND:</h1>
<p>Quantity: {data_ && data_.stats.custom.qty}</p>
<p>Domain_dofollow: {data_ && data_.stats.custom.domain_dofollow}</p>
<p>Domain_nofollow: {data_ && data_.stats.custom.domain_nofollow}</p>
<p>Limit: {data_ && data_.stats.custom.limit}</p>
<p>Domains_qty: {data_ && data_.stats.custom.domains_qty}</p>

</div>
</section>
<Ads/>
<Abouts type={"backlink_r"}/>
    </div>
  )
}

export default BacklinkR