import React from 'react'
import jsonData from '../data/data.json'

function Abouts({type}) {
  const data = jsonData.howTo;
 
  return (
    <div>
       <div className='pt'>
         {
           data && data.filter(el=> el.type == type)
           .map(x=>
           <div>
          <h1>HOW TO USE </h1>:
          <h1>{x.title}</h1>
         <p>{x.des}</p>
         <h2>Related Searches:</h2>
         <p><small>{x.r_1}</small></p>
         <p><small>{x.r_2}</small></p>
         <p><small>{x.r_3}</small></p>
         <p><small>{x.r_4}</small></p>
         <p><small>{x.r_5}</small></p>
         <p><small>{x.r_6}</small></p>
           </div>)
         }
        
         </div>
    </div>
  )
}

export default Abouts