import React,{useState} from 'react'
import Abouts from './Abouts';
import Ads from './Ads';
import Ads_one from './Ads_one';

function Dadjokes() {
  
const[data_,setData_]=useState(null);
const getDadjokes=async(e)=>{
	e.preventDefault();
await fetch("https://daddyjokes.p.rapidapi.com/random", {
	"method": "GET",
	"headers": {
		"x-rapidapi-host": "daddyjokes.p.rapidapi.com",
		"x-rapidapi-key":"2c2d2c930bmsh34aba5ca3857f41p13773fjsn95f9d1e0ed98"
	}
})
.then(response =>
 { 
 console.log(response);
 return response.json()
 })
 .then(data=>{
setData_(data)
 console.log(JSON.stringify(data))
 })
.catch(err => {
	console.error(err);
});

}
  return (
    <div>
      
<section class="section_id">
  <button className='btn' onClick={(e)=>getDadjokes(e)}>GET JOKES</button>
<div class="sec_id">

<h1>JOKES:</h1>
<p>{data_ && data_.joke}</p>
</div>
<Ads_one/>
</section>
<Ads/>
<Abouts type={"dadjokes"}/>
    </div>
  )
}

export default Dadjokes