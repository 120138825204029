import React,{useState,useEffect,useRef} from 'react'
import Abouts from './Abouts';
import Ads from './Ads'

function Img_resize() {
    const myCanvas = useRef(null);
    const ctx = useRef(null);
    const AspectRatio =  useRef(null);
    const[img,setImg]=useState(null);
    let activeImage,orignalWidthToHeightRatio; 
    const[widthInput,setWidthInput]=useState(0);
    const[HeightInput,setHeightInput]=useState(0);
    const onImageLoad=()=>{
      if(img&&myCanvas){
     const ctxs = myCanvas.current.getContext('2d');
     ctxs.fillStyle ='red';
   ctxs.fillRect(0,0,400,310);
   ctxs.drawImage(img,(400,256),2,40)
   ctx.current = ctxs;
      }
    };
    const handleChange=(e)=>{
      const image = new Image();
      console.log(URL.createObjectURL(e.target.files[0]))
      image.src = URL.createObjectURL(e.target.files[0]);
      console.log(image)

      image.onload =()=>{setImg(image)}
    }
    useEffect(()=>{
      onImageLoad();
    },[img,myCanvas]);
    /*
    const handleChange=(e)=>{
      
    setImg(URL.createObjectURL( e.target.files[0]));
  reader = new FileReader();
    reader.addEventListener(function(){
         console.log(reader.result)
       // openImage(reader.result);
        reader.readAsDataURL(e.target.files[0]);
        console.log(reader)
    })

    };
    const openImage=(imageSRC)=>{
        activeImage = new Image();
        activeImage.addEventListener('load',()=>{
        orignalWidthToHeightRatio = activeImage.width / activeImage.height;
        resize(activeImage.width,activeImage.height);
        activeImage.src = imageSRC;
        });
    };
    const resize=(w,h)=>{
 myCanvas.current.width = w;
 myCanvas.current.height = h;
setWidthInput(w);
setHeightInput(h);
ctx.drawImage(activeImage,0,0,w,h);
    }
    */
  return (
    <div>
    
<section class="section_id">
<div class="flex_id">
<h1>Resize your image</h1>
<label>
AspectRatio
<input type="checkbox" name="checkbox" style={{width:"30px",justifyContent:"center",alignItems:"center",textAlign:"center"}} ref={AspectRatio}  checked />

</label>
<br/>
<select>
<option>WhatsApp</option>
<option>Instagram</option>
<option>Facebook[post]</option>
<option>Reddit</option>
<option>LinkedIn</option>
</select>
<input type="file" onChange={(e)=>handleChange(e)}  required />
<div style={{display:"flex",textAlign:'center',justifyContent:"space-between",width:'100%',padding:"14px"}}>
    <input type='number' value={widthInput} onChange={(e)=>setWidthInput(e.target.value)} placeholder='width' style={{width:"50%"}}/>
    <span>x</span>
    <input type='number'  value={HeightInput} onChange={(e)=>setHeightInput(e.target.value)} placeholder='width' style={{width:"50%"}}/>
</div>
</div>
<div class="flex_id">
<Ads/>
</div>
</section>


<section class="section_id">
<div class="textarea">
Resized image
<canvas ref={myCanvas}/>
<button class="btn">Download</button>
</div>
<div class="textarea">
    Chosen image
    <img src={img} alt='hello'/>
</div>
</section>
<Abouts type={'img_resizer'} />
    </div>
  )
}

export default Img_resize