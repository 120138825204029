export const Footer = (props) => {
  const dates_=()=>{
    const date = new Date();
    const m_date = date.getFullYear();
    return m_date;
  }
  return (
    <div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; {dates_()} 234 Tools{' '}  Powered by 
            <a href='https://alpha02.netlify.app' rel='nofollow'>
              Alpha02
            </a>
             Designed by
            <a href='https://alpha02.netlify.ap'> 
             Issaaf Kattan
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
