import React,{useState} from 'react'

function Ads_one() {
  const ADS =[
    {img:"img/adsone (2).png",text:"ADS GOES HERE",link:"https://coderdivine.github.io/Dida"},
    {img:"img/adsone (1).png",text:"08150740406",link:"08150740406"}
]
const randoms_=()=>{
  const randoming = Math.floor(Math.random() * ADS.length);
  return randoming
};
const [t_random,setT_random]=useState(randoms_())

  return (
    <div>
        
<div class="flex_id">
  <a href={ADS[t_random].link}>
  <img src={ADS[t_random].img} alt={ADS[t_random].text} style={{width:"100%"}} />
  </a>
</div>
    </div>
  )
}

export default Ads_one