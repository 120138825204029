import React, { useState } from 'react'
import Abouts from './Abouts';
import Ads from './Ads';
import Ads_one from './Ads_one';

function Football() {
  
const[data_football,setData_football]=useState(null);
const[search,setSearch]=useState("");
const[fed,setFed]=useState("UEFA")
const getFootball =async(e)=>{
  e.preventDefault();
await fetch(`https://football-prediction-api.p.rapidapi.com/api/v2/predictions?market=+${search}&iso_date=2018-12-01&federation=+${fed}`, { 
"method": "GET", "headers": 
{ "x-rapidapi-host": "football-prediction-api.p.rapidapi.com", "x-rapidapi-key":"2c2d2c930bmsh34aba5ca3857f41p13773fjsn95f9d1e0ed98" 
} }) 
.then(response =>
       { 
       console.log(response);
       return response.json()
        })
        .then(data=>{
        setData_football(data)
        console.log(JSON.stringify(data))
        })
.catch(err => { alert(`${err}`); });

}
  return (
    <div>

<section class="section_id">
<div class="flex_id">
<h1>Enter football market and Federation below.</h1>
<input type="text" name="text" id="text" value={search} placeholder="e.g classic ..." onChange={(e)=>setSearch(e.target.value)} required />
<input type="text" name="text" id="text" value={fed} placeholder="e.g UEFA..." onChange={(e)=>setFed(e.target.value)} required />
<button class="btn" onClick={(e)=>getFootball(e)}>Search</button>
</div>
<Ads_one/>
</section>


<section class="section_id">
{
  data_football && data_football.map(x=><div>
    <div class="ron_id">
<h1>Home team: {x.home_team}<br /><span>vs</span> <br />Away team: {x.away_team}</h1>
<h2>market chosen:<span>{search}</span></h2>
<h2>competiton:<span>{x.competition_cluster}</span></h2><br />
<p>Prediction:{x.prediction}</p>
<p>Status:{x.status}</p>
<p>Season: {x.season}</p>
<h4>Scores: {x.result}</h4>
<small>{x.is_expired?<small style={{color:"red"}}>{x.is_expired}</small>:<small style={{color:'green'}}>{x.is_expired}</small>}</small>
<small>ID:{x.id}</small>
<hr />
</div>
  </div>).reverse()
}
</section>
<section class="section_id">
<div class="rem_id">
<img src="/storage/emulated/0/Download/Post.png" alt="abeg image" />
</div>
</section>
<Ads/>
<Abouts type={"footballs"}/>
    </div>
  )
}

export default Football