import React,{useState,useRef, useEffect} from 'react'
import Abouts from './Abouts';
import Ads from './Ads';
import Ads_one from './Ads_one';

function BacklinkG() {

//console.log(process.env.REACT_APP_M_KEY)
const[data_,setData_]=useState(null);
const[search,setSearch]=useState("");
const getBacklinkG=async(e)=>{
  e.preventDefault();
await fetch("https://url-shortener-service.p.rapidapi.com/shorten", {
 "method": "POST", "headers": {
  "content-type": "application/x-www-form-urlencoded", "x-rapidapi-host": "url-shortener-service.p.rapidapi.com", "x-rapidapi-key":"2c2d2c930bmsh34aba5ca3857f41p13773fjsn95f9d1e0ed98"}, 
  "body": { "url":search}
 })
 .then(response =>{ 
 console.log(response);
 return response.json()
 })
 .then(data=>{
 setData_(data);
 console.log(JSON.stringify(data))
 })
    .catch(err => {
       console.error(err);
       });

}

  return (
    <div>
      
<section class="section_id">

<div class="flex_id">
<h1>Input the URL to shorten in the search box below.</h1>

<input type="text" name="text" id="text" value={search} placeholder="Input URL" onChange={(e)=>setSearch(e.target.value)} required />
<button class="btn" onClick={(e)=>getBacklinkG(e)} >Short It</button>
</div>
<Ads_one />
</section>
<section class="section_id">
<div class="sec_id">
<h1>Old URL:</h1>
<p>{search}</p>
<h1>Short URL:</h1>
<p>{data_ && data_.result_url}</p>
</div>
</section>
<Ads/>
<Abouts type={"backlinkg"}/>
    </div>
  )
}

export default BacklinkG